.footer {
  width: 100%;
  height: 45vh;
  background: linear-gradient(
    to right,
    rgba(3, 17, 48, 1),
    rgba(9, 53, 150, 1)
  );
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .footer_main {
    width: 100%;
    height: 45vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    // display: flex;
    .footer_main_contact {
      height: 25vh;
      width: 100%;
      display: flex;
      // align-items: center;
      justify-content: center;
      gap: 30px;
      padding-top: 2.5rem;
      // flex-direction: column;
      .footer_main_contact_logo {
        display: flex;
        flex-direction: column;
        gap: 15px;
        width: 28%;
        .footer_logo {
          display: flex;
          align-items: center;
          gap: 10px;
          width: 15rem;
          img {
            height: 45px;
          }
          h3 {
            color: white;
            font-family: "Poppins", sans-serif;
            font-weight: 500;
            font-style: normal;
            font-size: 30px;
          }
        }
        .footer_location {
          color: white;
          font-size: 13px;
          font-weight: 400;
          font-family: "Roboto", sans-serif;
          width: 15rem;
        }
      }
      .footer_product-contactus {
        display: flex;
        width: 50%;
        // align-items: center;
        justify-content: space-between;
        // gap: 180px;

        .footer_features {
          display: flex;
          flex-direction: column;
          color: white;
          gap: 18px;
          padding: -5px;
          h4 {
            font-size: 20px;
            font-weight: 600;
            font-family: "Poppins", sans-serif;
          }
          p {
            font-family: "Poppins", sans-serif;
            font-size: 16px;
            font-weight: 300;
            cursor: pointer;
          }
          a {
            text-decoration: none;
            color: white;
            font-size: 16px;
            font-family: "Poppins", sans-serif;
          }
        }
      }
    }
  }
  .footer_copyright {
    width: 100%;
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: center;
    .footer_copy_wrap {
      width: 80%;
      height: 8vh;
      border-top: 2px solid white;
      // padding: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: white;
      font-family: "Roboto", sans-serif;
      font-weight: 300;
      margin-top: 15px;
      .footer_copyright_icons {
        display: flex;
        gap: 50px;
        a {
          text-decoration: none;
          color: white;
        }
        .icons {
          font-size: 35px;
          cursor: pointer;
        }
      }
    }
  }
}

@media (min-width: 430px) and (max-width: 768px) {
  .footer {
    width: 100%;
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    .footer_main {
      width: 90%;
      height: 30vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 20px;
      // gap: 20px;

      .footer_main_contact {
        // padding-top: -60px;
        height: 25vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        // padding-top: 0px;
        .footer_main_contact_logo {
          .footer_logo {
            margin-top: 20px;
            img {
              height: 30px;
            }
            h3 {
              font-size: 25px;
            }
          }
        }
        .footer_product-contactus {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 10px;
          width: 100%;
          height: 40vh;

          .footer_features {
            gap: 10px !important;
            margin-top: 20px;
            margin-bottom: 20px;
          }

          // flex-direction: column;
          .footer_features {
            h4 {
              font-size: 15px;
            }
            p {
              font-size: 10px;
            }
          }
        }
      }
    }
    .footer_copyright {
      width: 100%;
      height: 10vh;
      .footer_copy_wrap {
        border-top: 2px solid white;
        // padding: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: white;
        font-family: "Roboto", sans-serif;
        font-weight: 300;
        margin-top: 15px;
        p {
          font-size: 10px;
        }
        .footer_copyright_icons {
          display: flex;
          gap: 10px;
          .icons {
            font-size: 15px;
            cursor: pointer;
          }
        }
      }
    }
  }
}

@media (max-width: 430px) {
  .footer {
    width: 100%;
    height: 55vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    .footer_main {
      width: 85%;
      height: 40vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 20px;
      // gap: 20px;

      .footer_main_contact {
        // padding-top: -60px;
        height: 20vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        // padding-top: 0px;
        .footer_main_contact_logo {
          margin-top: 0px;
          .footer_logo {
            margin-top: 20px;
            img {
              height: 30px;
            }
            h3 {
              font-size: 25px;
            }
          }
        }
        .footer_product-contactus {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 30px;
          .footer_features {
            gap: 10px !important;
          }

          // flex-direction: column;
          .footer_features {
            h4 {
              font-size: 12px;
            }
            p {
              font-size: 8px;
            }
            a{
              font-size:  8px ;
            }
          }
        }
      }
    }
    .footer_copyright {
      width: 100%;
      // height: 20vh;
      display: flex;
      align-items: center;
      justify-content: center;
      .footer_copy_wrap {
        border-top: 2px solid white;
        // height: 10vh;
        // padding: 20px;
        width: 85% !important;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: white;
        font-family: "Roboto", sans-serif;
        font-weight: 300;
        margin-top: 15px;
        p {
          font-size: 10px;
        }
        .footer_copyright_icons {
          display: flex;
          gap: 10px;
          .icons {
            font-size: 15px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
