.payment_processing{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: linear-gradient(rgba(9, 53, 150, 1), rgba(3, 17, 48, 1));
    img{
        height: 300px;
    }
}