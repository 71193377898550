.askques{
    min-height: 80vh;
    width: 100%;
    background-color: rgba(9, 53, 150, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Roboto", sans-serif;
    color: white;
    .askques_main{
        width: 75%;
        min-height: 70vh;
        display: flex;
        .askques_main_left{
            flex: 0.5;
            margin-top: 80px;
            h1{
                font-size: 36px;
            }
            // border: 2px solid white;
        }
        .askques_main_right{
            flex: 0.5;
            // border: 2px solid white;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            
            .accordian1{
                background-color: transparent;
                color: white;
                border: 0px solid;
                min-height: 10vh;
                display: flex;
                // align-items: center;
                flex-direction: column;
                justify-content: center;
                margin: 0px !important;
                box-shadow: none;
                &::before{
                    height: 0px !important;
                }
                // border-top: 0px !important;
                .acoordian_heading1{
                    display: flex;
                    flex-direction: row-reverse;
                    gap: 40px;
                    p{
                        color: rgba(246, 247, 248, 1);
                        font-weight: 400;
                        font-size: 20px;
                    }
                    .icon_3{
                        height: 40px;
                        width: 40px;
                        border-radius: 5px;
                        background-color: white;
                        color: black;
                        font-size: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    // width: 200px;
                }
                .accordian_details1{
                    margin-left: 82px;
                    p{
                        font-size: 16px;
                        font-weight: 400;
                        color: rgba(246, 247, 248, 1);
                    }

                }
                

            }
        }
    }
}