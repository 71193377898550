.login {
  width: 100%;
  height: 100vh;
  font-family: "Roboto", sans-serif;
  background: linear-gradient(rgba(9, 53, 150, 1), rgba(3, 17, 48, 1));
  .login_navbar {
    height: 16vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .signup1_main {
    height: 80vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .signup1_card {
      margin-top: 50px;
      height: 55vh !important;
      background-color: rgb(246, 247, 248);
      border: 1px solid rgb(60, 77, 255);
      border-radius: 20px !important;
      display: flex;
      flex-direction: column;
      justify-content: start !important;
      gap: 2px !important;
      transition: 0.25s ease-in-out !important;
      padding: 10px !important;
      .signup1_card_top {
        height: 12vh !important;
        width: 100%;
        display: flex;
        align-items: start !important;
        justify-content:center;
        flex-direction: column;
        gap: 1px;
        margin-left: 40px;
        h1 {
          font-size: 18px;
        }
        p {
          font-size: 14px !important;
          font-weight: 400;
        }
      }
      .signup1_card_top_logo {
        height: 10vh !important;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 40px;
        .signup1_card_main{
          width: 350px !important;
          padding: 10px !important;
          height: 20px !important;
          border-radius: 15px !important;
          border: 2.5px  solid rgb(84, 99, 254) !important;
          font-size: 16px !important;
          display: flex !important;
          align-items: center !important;
          justify-content: center !important;
          gap: 10px !important;
        }
        .signup1_card_break{
          display: flex;
          gap: 20px;
          margin-top: 15px;
          .line{
            border-bottom: 1px solid rgb(84, 99, 254) !important;
            width: 160px !important;
            height: 10px !important;
          }
        }
        
      }
      .signup1_card_top_heading {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 10px;
        h3 {
          font-size: 16px;
        }
        p {
          font-size: 13px;
        }
      }

      .signup1_card_middle {
        height: 15vh !important;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 20px !important;
        transition: 0.25s ease-in-out !important;
        .card1_input {
          display: flex !important;
          flex-direction: column !important;
          justify-content: start !important;
          gap: 12px !important;
          span {
            font-size: 16px !important;
            color: black;
            font-weight: 500;
          }
          .card1_input_input{
            display: flex;
            width: 370px !important;
            justify-content: center !important;
            align-items: center;
            gap: 10px !important;
            border: 0px  solid rgb(84, 99, 254) !important;
            border-radius: 15px !important;
            .icon{
              height: 20px !important;
              padding: 10px !important;
              width: 16px !important;;
              border-radius: 15px !important;
              cursor: pointer;
            }
            input {
              width: 350px !important;
              padding: 10px !important;
              height: 20px !important;
              border-radius: 15px !important;
              border: 2.5px  solid rgb(84, 99, 254) !important;
              font-size: 16px !important;
              padding-left: 10px;
              input:focus {
                outline: none !important;
              }
            }
            &.active{
              border: 2.5px  solid rgb(84, 99, 254) !important;
              input{
                border: 0px !important;
              }
            }
          }
          input {
            width: 350px !important;
            padding: 10px !important;
            height: 20px !important;
            border-radius: 15px !important;
            border: 2.5px  solid rgba(60, 77, 255, 1) !important;
            font-size: 16px !important;
            padding-left: 10px;
            input:focus {
              outline: none;
            }
          }
          p {
            font-size: 14px !important;
            margin-left: 0px !important;
            width: 350px !important;
            display: flex !important;
            justify-content: space-between !important;
            span{
              color: rgba(60, 77, 255, 1) !important;
              cursor: pointer !important;
            }
          }
        }
        &.active{
          height: 33vh !important;
        }
      }
      .signup1_card_bottom {
        height: 10vh !important;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-direction: column;
        margin-top: 10px !important;
        .bottom1_button {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 10px !important;
          
          
          p {
            .nav {
              text-decoration: none;
            }
            span {
              text-decoration: none;
              font-weight: 500;
            }
          }

          button {
            width: 380px !important;
            height: 45px !important;
            padding: 10px !important;
            border-radius: 10px !important;
            background-color: rgb(60, 77, 255);
            color: white;
            border: 0px;
            cursor: pointer;
            font-size: 16px !important;
          }
        }
        p {
          text-align: start !important;
          font-size: 13px !important;
          margin-left: 20px !important;
          width: 400px !important;
          margin-top: 10px !important;
          span {
            text-decoration: underline;
            cursor: pointer;
            color: rgb(84, 99, 254);
          }
        }
      }
      &.active{
        height: 72vh !important;
      }
    }
  }
}
