.error {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  button {
    height: 50px;
    width: 150px;
    padding: 5px;
    color: white;
    border-radius: 50px;
    font-size: 13px;
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    font-style: normal;
    border: 0px solid black;
    padding-left: 20px;
    padding-right: 20px;
    margin-right: 5px;
    cursor: pointer;
    background-color: rgb(65, 61, 250);
    &:hover {
      background-color: rgb(62, 59, 225);
    }
  }
}
