.compare{
    width: 100%;
    height: auto;
    background: linear-gradient(rgba(3, 17, 48, 1),rgba(9, 53, 150, 1),);
    font-family: "Roboto", sans-serif;
    .compare_nav{
        height: 30vh;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
    }
    .comapre_main{
        height: auto;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 50px;
        .compare_main_card{
            width: 75%;
            // height: 70vh;
            // border: 2px solid  rgba(246, 247, 248, 1);
            // border-radius: 40px;
            // background-color: rgba(246, 247, 248, 0.1);
            .compare_main_card_nav{
                width: 99.9%;
                height: 10vh;
                border: 1px solid rgba(246, 247, 248, 1);
                background-color: rgba(246, 247, 248, 0.1);
                display: flex;
                color: white;
                border-top-left-radius: 40px;
                border-top-right-radius: 40px;
                .compare_main_card_nav_1{
                    width: 30%;
                    height: 10vh;
                    display: flex;
                    align-items: center;
                    justify-content: start;
                    padding-left: 60px;
                    // border: 1px solid white;
                    h3{
                        font-weight: 400;
                        font-size: 16px;
                    }
                    
                }
                .compare_main_card_nav_2{
                    width: 70%;
                    height: 10vh;
                    display: flex;
                    align-items: center;
                    justify-content: space-evenly;
                    h3{
                        font-weight: 400;
                        font-size: 16px;
                    }
                    // border: 1px solid white;
                }
            }
            .compare_main_card_dropdown{
                width: 100%;
                // height: 60vh;
                color: white;
                .accordian{
                    // background-color: transparent;
                    min-height: 10vh;
                    border-bottom: 1px solid white;
                    background-color: rgba(246, 247, 248, 0.1);
                    color: white;
                    padding-left: 35px;
                    padding-right:50px;
                    border-left: 1px solid white;
                    border-right: 1px solid white;
                    margin: 0px !important;
                    display: flex;
                    flex-direction: column;
                    // align-items: center;
                    // align-items: center;

                    // flex-direction: row-reverse;
                    // margin-top: 0px !important;
                    .acoordian_heading{
                        display: flex;
                        flex-direction: row-reverse;
                        gap: 5px;
                        min-height: 10vh;
                    }
                    .icon_2{
                        color: white;
                    }
                    .accordian_details{
                        display: flex;
                        width: 100%;
                        height: 5vh;
                        
                        .accordian_deatails_1{
                            flex: 0.30;
                            // border: 1px solid white;
                            display: flex;
                            align-items: center;
                            justify-content: start;
                            p{
                                margin-left: 40px;
                                font-size: 15px;
                                font-weight: 300;
                            }
                        }
                        .accordian_deatails_2{
                            flex: 0.70;
                            // border: 1px solid white;
                            display: flex;
                            align-items: center;
                            justify-content: space-evenly;
                            gap: 10px;

                        }

                    }
                    // height: 10vh;
                }
                .accordian_1{
                    // background-color: transparent;
                    min-height: 10vh;
                    border-bottom: 1px solid white;
                    background-color: rgba(246, 247, 248, 0.1);
                    color: white;
                    padding-left: 35px;
                    padding-right:50px;
                    border-left: 1px solid white;
                    border-right: 1px solid white;
                    margin: 0px !important;
                    display: flex;
                    flex-direction: column;
                    border-bottom-left-radius: 40px;
                    border-bottom-right-radius: 40px;
                    // align-items: center;
                    // align-items: center;

                    // flex-direction: row-reverse;
                    // margin-top: 0px !important;
                    .acoordian_heading{
                        display: flex;
                        flex-direction: row-reverse;
                        gap: 5px;
                        min-height: 10vh;
                    }
                    .icon_2{
                        color: white;
                    }
                    .accordian_details{
                        display: flex;
                        width: 100%;
                        height: 5vh;
                        
                        .accordian_deatails_1{
                            flex: 0.35;
                            // border: 1px solid white;
                            display: flex;
                            align-items: center;
                            justify-content: start;
                            p{
                                margin-left: 40px;
                                font-size: 15px;
                                font-weight: 300;
                            }
                        }
                        .accordian_deatails_2{
                            flex: 0.65;
                            // border: 1px solid white;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            gap: 190px;

                        }

                    }
                    // height: 10vh;
                }
                
            }

        }
    }

}