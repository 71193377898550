.features {
  height: 210vh;
  width: 100%;
  background-color: rgb(255, 255, 255);
  .features_navbar {
    height: 25vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    font-family: "Roboto", sans-serif;
    color: rgba(40, 39, 39, 1);
    h1 {
      font-size: 36px;
    }
    p {
      font-weight: 400;
      font-size: 18px;
    }
  }
  .features_middle {
    height: 98vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .features_middle_data {
      height: 90vh;
      width: 80%;
      background: linear-gradient(rgba(3, 17, 48, 1), rgba(9, 53, 150, 1));
      border-radius: 30px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      .features_middle_card {
        // border: 2px solid white;
        height: 75vh;
        width: 40%;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-direction: column;
        font-family: "Roboto", sans-serif;
        // gap: 10px;
        .features_middle_card_navbar {
          width: 100%;
          height: 5vh;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
        }
        .features_middle_card_card {
          width: 100%;
          height: 60vh;
          background-color: white;
          border-radius: 20px;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          flex-direction: column;
          // gap: 20px;
          h2 {
            font-size: 20px;
            font-weight: 400;
            color: rgba(40, 39, 39, 1);
            width: 65%;
          }
          .features_middle_card_card_tick {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 25px;
            p {
              width: 55%;
            }
          }
        }
      }
    }
  }

  .features_footer {
    height: 60vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Roboto", sans-serif;
    .fetures_footer_card {
      height: 45vh;
      width: 80%;
      background: linear-gradient(
        to right,
        rgba(3, 17, 48, 1),
        rgba(9, 53, 150, 1)
      );
      border-radius: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: white;
      .features_footer_card_half {
        padding-left: 10vh;
        display: flex;
        flex-direction: column;
        gap: 20px;
        h1 {
          font-size: 36px;
        }
        p {
          font-weight: 300;
          font-size: 16px;
        }
      }
      .features_footer_card_image {
        width: 40vh;
        height: 35vh;
        padding-right: 5vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 10px;

        img {
          height: 22vh;
        }
        button {
          background-color: transparent;
          border: 0px solid black;
          font-size: 12px;
          font-weight: 200;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 2px;
        }
      }
    }
  }
  .features_bottom {
    height: 25vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Roboto", sans-serif;
    .features_bottom_data {
      width: 80%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .features_bottom_heading {
        display: flex;
        flex-direction: column;
        gap: 10px;
        color: rgba(40, 39, 39, 1);
        h1 {
          font-size: 48px;
        }
        p {
          font-size: 16px;
        }
      }
      .features_bottom_button {
        display: flex;
        gap: 20px;
        .butt-1 {
          height: 6vh;
          padding-left: 5vh;
          padding-right: 5vh;
          border-radius: 20px;
          border: 2px solid rgb(65, 61, 250);
          background-color: rgb(65, 61, 250);
          color: white;
          cursor: pointer;
          box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
            rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
        }
        .butt-2 {
          height: 6vh;
          padding-left: 5vh;
          padding-right: 5vh;
          border-radius: 20px;
          border: 2px solid rgb(65, 61, 250);
          background-color: white;
          color: rgb(65, 61, 250);
          cursor: pointer;
          box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
            rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
        }
      }
    }
  }
}

@media (min-width: 430px) and (max-width: 768px) {
  .features {
    height: 146vh;
    width: 100%;
    background-color: rgb(255, 255, 255);
    .features_navbar {
      height: 15vh;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 20px;
      font-family: "Roboto", sans-serif;
      color: rgba(40, 39, 39, 1);
      p {
        font-weight: 400;
        font-size: 11px;
      }
      h1 {
        font-size: 30px;
      }
    }
    .features_middle {
      height: 80vh;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .features_middle_data {
        height: 80vh;
        width: 80%;
        background: linear-gradient(rgba(3, 17, 48, 1), rgba(9, 53, 150, 1));
        border-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-direction: column;

        .features_middle_card {
          height: 75vh;
          width: 40%;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          flex-direction: column;
          font-family: "Roboto", sans-serif;
          gap: 1px;
          .features_middle_card_navbar {
            width: 100%;
            height: 0vh;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            h3 {
              font-size: 14px;
            }
          }
          .features_middle_card_card {
            width: 180%;
            height: 32vh;
            background-color: white;
            border-radius: 20px;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            flex-direction: column;
            h2 {
              font-size: 12px;
              font-weight: 400;
              color: rgba(40, 39, 39, 1);
              width: 65%;
            }
            .features_middle_card_card_tick {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 15px;
              p {
                font-size: 10px;
                width: 60%;
              }
              .icon_1 {
                font-size: 12px;
              }
            }
          }
        }
      }
    }

    .features_footer {
      height: 25vh;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: "Roboto", sans-serif;
      .fetures_footer_card {
        height: 20vh;
        width: 80%;
        background: linear-gradient(
          to right,
          rgba(3, 17, 48, 1),
          rgba(9, 53, 150, 1)
        );
        border-radius: 30px;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;
        color: white;
        gap: 20px;
        .features_footer_card_half {
          padding-left: 25px;
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 20px;
          p {
            font-size: 10px;
            font-weight: 300;
          }
          h1 {
            font-size: 15px;
          }
        }
        .features_footer_card_image {
          width: 10vh;
          height: 10vh;
          padding-right: 2vh;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;

          img {
            height: 8vh;
          }
          button {
            background-color: transparent;
            border: 0px solid black;
            font-size: 6px;
            width: 10vh;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 2px;
            .icon_6 {
              font-size: 12px;
            }
          }
        }
      }
    }
    .features_bottom {
      height: 25vh;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: "Roboto", sans-serif;
      flex-direction: column;
      gap: 25px;
      .features_bottom_data {
        width: 80%;
        height: 22vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        .features_bottom_heading {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          gap: 5px;
          color: rgba(40, 39, 39, 1);
          h1 {
            font-size: 30px;
          }
          p {
            font-size: 11px;
          }
        }
        .features_bottom_button {
          display: flex;
          flex-direction: column;
          gap: 10px;
          .butt-1 {
            height: 4vh;
            width: 35vh;
            padding-left: 4vh;
            padding-right: 4vh;
            border-radius: 20px;
            font-size: 10px;
            border: 2px solid rgb(65, 61, 250);
            background-color: rgb(65, 61, 250);
            color: white;
            cursor: pointer;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
              rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
          }
          .butt-2 {
            height: 4vh;
            width: 35vh;
            padding-left: 4vh;
            padding-right: 4vh;
            border-radius: 20px;
            font-size: 10px;
            border: 1px solid rgb(65, 61, 250);
            background-color: white;
            color: rgb(65, 61, 250);
            cursor: pointer;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
              rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
          }
        }
      }
    }
  }
}

@media all and (max-width: 430px) {
  .features {
    height: 146vh;
    width: 100%;
    background-color: rgb(255, 255, 255);
    .features_navbar {
      height: 15vh;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 20px;
      font-family: "Roboto", sans-serif;
      color: rgba(40, 39, 39, 1);
      p {
        font-weight: 400;
        font-size: 11px;
      }
      h1 {
        font-size: 30px;
      }
    }
    .features_middle {
      height: 80vh;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .features_middle_data {
        height: 80vh;
        width: 80%;
        background: linear-gradient(rgba(3, 17, 48, 1), rgba(9, 53, 150, 1));
        border-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-direction: column;

        .features_middle_card {
          height: 75vh;
          width: 40%;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          flex-direction: column;
          font-family: "Roboto", sans-serif;
          gap: 1px;
          .features_middle_card_navbar {
            width: 100%;
            height: 0vh;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            h3 {
              font-size: 14px;
            }
          }
          .features_middle_card_card {
            width: 180%;
            height: 30vh;
            background-color: white;
            border-radius: 20px;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            flex-direction: column;
            h2 {
              font-size: 10px;
              font-weight: 400;
              color: rgba(40, 39, 39, 1);
              width: 65%;
            }
            .features_middle_card_card_tick {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 15px;
              p {
                font-size: 8px;
                width: 55%;
              }
              .icon_1 {
                font-size: 12px;
              }
            }
          }
        }
      }
    }

    .features_footer {
      height: 25vh;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: "Roboto", sans-serif;
      .fetures_footer_card {
        height: 18vh;
        width: 80%;
        background: linear-gradient(
          to right,
          rgba(3, 17, 48, 1),
          rgba(9, 53, 150, 1)
        );
        border-radius: 30px;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;
        color: white;
        gap: 20px;
        .features_footer_card_half {
          padding-left: 25px;
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 20px;
          p {
            font-size: 8px;
            font-weight: 300;
          }
          h1 {
            font-size: 12px;
          }
        }
        .features_footer_card_image {
          width: 12vh;
          height: 10vh;
          padding-right: 2vh;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;

          img {
            height: 8vh;
          }
          button {
            background-color: transparent;
            border: 0px solid black;
            font-size: 6px;
            width: 10vh;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 2px;
            .icon_6 {
              font-size: 12px;
            }
          }
        }
      }
    }
    .features_bottom {
      height: 25vh;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: "Roboto", sans-serif;
      flex-direction: column;
      gap: 25px;
      .features_bottom_data {
        width: 80%;
        height: 20vh;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        gap: 20px;
        .features_bottom_heading {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          gap: 10px;
          color: rgba(40, 39, 39, 1);
          h1 {
            font-size: 25px;
          }
          p {
            font-size: 9px;
          }
        }
        .features_bottom_button {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 10px;
          .remove {
            border: 0px solid black;
          }
          .butt-1 {
            height: 4vh;
            width: 25vh;
            padding-left: 4vh;
            padding-right: 4vh;
            border-radius: 20px;
            font-size: 8px;
            border: 2px solid rgb(65, 61, 250);
            background-color: rgb(65, 61, 250);
            color: white;
            cursor: pointer;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
              rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
          }
          .butt-2 {
            height: 4vh;
            width: 25vh;
            padding-left: 4vh;
            padding-right: 4vh;
            border-radius: 20px;
            font-size: 8px;
            border: 1px solid rgb(65, 61, 250);
            background-color: white;
            color: rgb(65, 61, 250);
            cursor: pointer;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
              rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
          }
        }
      }
    }
  }
}
