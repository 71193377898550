.navbar {
  height: 60px;
  width: 85%;
  background-color: rgba(40, 39, 39, 1);
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  position: fixed;
  margin-top: 20px;
  z-index: 999;
  .navbar_logo {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    gap: 10px;
    img {
      height: 50px;
      border-radius: 20px;
    }
    h4 {
      font-size: 16px;
      font-weight: 600;
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      font-style: normal;
    }
  }
  .navbar_routes {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    .p_hover {
      width: 80px;
    }
    p {
      font-size: 13px;
      font-family: "Roboto", sans-serif;
      font-weight: 400;
      font-style: normal;
      color: rgb(246, 246, 246);
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 2px;
      padding: 5px;
      padding-left: 10px;
      border-radius: 10px;
    }
    .resourse_data1 {
      background-color: transparent;
      &.active {
        color: white;
        background-color: rgb(62, 62, 62);
      }
    }
    .resourse_data {
      position: absolute;
      background-color: white;
      margin-top: 70px;
      margin-left: 320px;
      width: 25vh;
      height: 21vh;
      border-radius: 10px;
      background-color: rgb(40, 39, 39);
      border: 2px solid white;
      font-family: "Roboto", sans-serif;
      display: none;
      align-items: start;
      justify-content: space-between;
      p {
        color: rgb(255, 255, 255);
      }
      &.active {
        display: flex;
      }
      .resource_data_main {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: space-evenly;
        gap: 14px;
        width: 120px;
        padding: 15px;
        h4 {
          font-weight: 500;
          font-size: 14px;
        }
        p {
          padding: 0px !important;
          padding-left: 0px !important;
        }
        
      }
    }
  }
  .navbar_buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    .but-1 {
      height: 50px;
      padding: 5px;
      color: white;
      border-radius: 50px;
      font-size: 12px;
      font-family: "Roboto", sans-serif;
      font-weight: 400;
      font-style: normal;
      border: 0px solid black;
      padding-left: 20px;
      padding-right: 20px;
      cursor: pointer;
      background-color: rgb(62, 62, 62);
    }
    .but-2 {
      height: 50px;
      padding: 5px;
      color: white;
      border-radius: 50px;
      font-size: 12px;
      font-family: "Roboto", sans-serif;
      font-weight: 400;
      font-style: normal;
      border: 0px solid black;
      padding-left: 20px;
      padding-right: 20px;
      margin-right: 5px;
      cursor: pointer;
      background-color: rgb(65, 61, 250);
    }
  }
  .mobile {
    display: none;
  }
}
// }

@media (min-width: 768px) and (max-width: 1023px) {
  .navbar {
    height: 50px;
    width: 85%;
    background-color: rgba(40, 39, 39, 1);
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // position: absolute !important;
    color: white;
    .navbar_logo {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      gap: 10px;
      img {
        height: 40px;
        border-radius: 20px;
        // width: 35px;
      }
      h4 {
        font-size: 12px;
        font-weight: 600;
        font-family: "Poppins", sans-serif;
        font-weight: 500;
        font-style: normal;
      }
    }
    .navbar_routes {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 15px;
      p {
        font-size: 10px;
        font-family: "Roboto", sans-serif;
        font-weight: 400;
        font-style: normal;
        color: rgb(246, 246, 246);
        cursor: pointer;
      }
      select {
        font-size: 10px;
        font-family: "Roboto", sans-serif;
        font-weight: 400;
        font-style: normal;
        color: rgb(246, 246, 246);
        background-color: rgba(40, 39, 39, 1);
        border: 0px solid black;
        outline: none;
        cursor: pointer;
      }
    }
    .navbar_buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;

      // margin-left: 10px;

      .but-1 {
        height: 40px;
        padding: 5px;
        color: white;
        border-radius: 50px;
        font-size: 10px;
        font-family: "Roboto", sans-serif;
        font-weight: 400;
        font-style: normal;
        border: 0px solid black;
        padding-left: 20px;
        padding-right: 20px;
        cursor: pointer;
        background-color: rgb(62, 62, 62);
      }
      .but-2 {
        height: 40px;
        padding: 5px;
        color: white;
        border-radius: 50px;
        font-size: 10px;
        font-family: "Roboto", sans-serif;
        font-weight: 400;
        font-style: normal;
        border: 0px solid black;
        padding-left: 20px;
        padding-right: 20px;
        margin-right: 5px;
        cursor: pointer;
        background-color: rgb(65, 61, 250);
      }
    }
    .mobile {
      display: none;
    }
  }
}

@media all and (max-width: 768px) {
  .navbar {
    height: 50px !important;
    width: 100%;
    border-radius: 0px;
    background-color: rgba(40, 39, 39, 1);
    // margin-top: 8px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    position: absolute !important;
    margin-top: 0px !important;
  }
  .mobile {
    display: block !important;
    margin-right: 20px !important;
  }
  .navbar_logo {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      height: 35px !important;
    }
    h4 {
      font-size: 14px !important;
    }
  }
  .navbar_routes {
    display: none !important;
    flex-direction: column;
    position: absolute;
    align-items: start !important;
    // align-items: flex-start;
    // justify-content: flex-start !important;
    width: 99.5px;
    margin-top: 44px !important;
    margin-left: 67%;
    gap: 5px !important;
    background-color: white;
    padding: 10px !important;
    border-top-left-radius: 10px;
    // height:50px;
    // right: 23px ;
    right: 0px;
    z-index: -999;
    .p_hover{
      width: 38px !important;
    }

    &.active {
      display: flex !important;
      right: 23px;
      z-index: 0;
    }

    p {
      font-size: 10px !important;
      font-family: "Roboto", sans-serif;
      font-weight: 400;
      font-style: normal;
      color: rgb(246, 246, 246);
      cursor: pointer;
      color: black !important;
    }
    .resourse_data1 {
      background-color: transparent !important;
      &.active {
        background-color: transparent !important;
      }
    }
    .resourse_data {
      position: absolute;
      background-color: white;
      z-index: -999 !important;
      width: 116.5px !important;
      height: 16vh !important;
      border-radius: 8px !important;
      background-color: rgb(255, 255, 255) !important;
      color: black !important;
      border: 2px solid white;
      font-family: "Roboto", sans-serif;
      display: none !important;
      align-items: start;
      flex-direction: column;
      // margin-top: 130px !important;
      // margin-left: 400px !important;
      justify-content: start !important;

      // margin: 10px;
      // justify-content: space-between;
      p {
        color: rgb(0, 0, 0) !important;
      }
      &.active {
        display: flex !important;
        z-index: 0 !important;
        right: 0px;
        margin-top: 145px !important;
        // background-color: gray;
      }
      .resource_data_main {
        // z-index: -999 !important;
        // margin-left: 0px !important;
        // margin-left: 10px !important;
        width: 90% !important;
        padding-left: 20px !important;
        h4 {
          font-size: 10px !important;
        }
        p {
          font-size: 8px !important;
          color: rgb(0, 0, 0) !important;
        }

        &.active {
          flex-direction: column;
          // z-index: 0 !important;
        }
      }
    }
  }
  .navbar_buttons {
    display: none !important;
    flex-direction: column;
    position: absolute;
    align-items: start !important;
    justify-content: flex-start;
    top: 220px;
    margin-left: 67%;
    padding: 10px;
    width: 99.5px;
    background-color: white;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    right: 0px;
    z-index: -99;

    &.active {
      display: flex !important;
      right: 23px;
      z-index: 0;
      margin-top: -18px !important;
    }
    &.activepluse {
      margin-top: 72px !important;
    }
    .but-1 {
      font-size: 8px !important;
      height: 20px !important;
    }
    .but-2 {
      font-size: 8px !important;
      height: 20px !important;
    }
  }
}
