.pricing {
  width: 100%;
  height: 195vh;
  background: linear-gradient(rgba(9, 53, 150, 1), rgba(3, 17, 48, 1));
  font-family: "Roboto", sans-serif;
  .pricing_navbar {
    height: 15vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .pricing_button {
    width: 100%;
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      height: 6vh;
      width: 22vh;
      background-color: transparent;
      color: white;
      font-size: 15px;
      border: 2px solid white;
      border-radius: 25px;
      cursor: pointer;
      font-weight: 600;
    }
  }
  .pricing_heading {
    height: 30vh;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
    // gap: 20px;
    color: white;

    h1 {
      font-size: 45px;
      text-align: center;
    }
    p {
      font-size: 16px;
      font-weight: 300;
    }
  }
  .pricing_toggle{
    width: 100%;
    height: 12vh;
    display: flex;
    align-items: center;
    justify-content: center;
   
    color: white;
    .pricing_toggle_conent{
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
      margin-left: 80px;
      // .switch{
      //   background-color: transparent;
      //   box-shadow: 0 2px 20px rgb(255, 255, 255);
      // }
    }
    h3{
      // margin-right: 20px;
    }
    p{
      height: 30px;
      width: 85px;
      display: flex;
      align-items: center;
      justify-content: center;
      // padding: 10px;
      border: 2px solid white;
      border-radius: 20px;
    }
  }
  .pricing_cards{
    width: 100%;
    height: 150vh;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    .pricing_card_free{
      height: 100vh;
      width: 40vh;
      border: 2px solid rgba(143, 143, 143, 1);
      background: rgba(255, 255, 255, 0.06);
      border-radius: 30px;
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(0.1px);
      -webkit-backdrop-filter: blur(0.1px);
      padding: 25px;
      color: rgba(245, 245, 245, 1);
      .pricing_card_free_heading{
        height: 13vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        p{
          font-size: 15px;
        }
      }
      .pricing_card_free_price{
        width: 100%;
        height: 15vh;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .pricing_card_free_button{
        width: 100%;
        height: 5vh;
        display: flex;
        align-items: center;
        justify-content: center;
        button{
          background-color: rgba(65, 61, 250, 1);
          color: white;
          border: 0px solid red;
          height: 7vh;
          width: 100%;
          border-radius: 40px;
          font-size: 15px;
          cursor: pointer;

        }
      }
      .pricing_card_free_contain{
        height: 75vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 25px;
        margin-top: 40px;
        color: rgba(246, 247, 248, 1);
        .check{
          height: 2vh;
          display: flex;
          align-items: center;
          gap: 10px;
          p{
            font-size: 13px;
          }
        }
      }
    }
    .pricing_card_premium{
      height: 120vh;
      width: 46vh;
      border: 2px solid rgb(251, 250, 250);
      // background: rgba(255, 255, 255, 0.06);
      background: linear-gradient(rgba(9, 53, 150, 1), rgba(3, 17, 48, 1));
      border-radius: 30px;
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(0.1px);
      -webkit-backdrop-filter: blur(0.1px);
      // padding: 25px;
      color: rgba(245, 245, 245, 1);
      margin-bottom: 75px;

      .pricing_card{
        width:38vh;
        background-color:rgba(36, 46, 153, 1);
        padding: 25px;
        border-radius: 30px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        height: 40vh;
        position: absolute;
        .pricing_card_free_heading{
          height: 10vh;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          // flex-direction: column;
          gap: 20px;
          p{
            font-size: 15px;
            height: 30px;
            width: 85px;
            display: flex;
            align-items: center;
            justify-content: center;
            // padding: 10px;
            border: 2px solid white;
            border-radius: 20px;
          }
        }
        .pricing_card_free_heading_2{
          height: 12vh;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          gap: 5px;
          // border: 2px solid red;
          .pricing_card_free_subheading{
            height: 5vh;
            width: 100%;
            display: flex;
            justify-content: space-between;
            // border: 2px solid red;


            .toggle{
              cursor: pointer;
            }
            
          }
          .dropdown{
            height: 30vh;
            background-color: rgba(30, 30, 49, 1);
            border-radius: 20px;
            border: 2px solid white;
            margin-bottom: 5px;
            margin-top: 5px;
            display: flex;
            flex-direction: column;
            align-items: center;
            color: rgba(246, 247, 248, 1);
            // justify-content: center;
            .dropdown_top{
              flex: 0.5;
              width: 85%;
              padding: 10px;
              display: flex;
              flex-direction: column;
              justify-content: space-evenly;
              gap: 10px;
              .dropdown_top_heading{
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                h3{
                  font-weight: 300;
                }
              }
              .dropdown_top_subheading{
                p{
                  font-weight: 300;
                }
              }
            }
            .dropdown_down{
              flex: 0.5;
              border-top: 1px solid white;
              width: 85%;
              padding: 10px;
              display: flex;
              flex-direction: column;
              justify-content: space-evenly;
              gap: 10px;
              .dropdown_top_heading{
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                h3{
                  font-weight: 300;
                }
              }
              .dropdown_top_subheading{
                p{
                  font-weight: 300;
                }
              }
            }

          }
          p{
            font-size: 14px;
            // height: 10vh;
          }
          
        }


        .pricing_card_free_price{
          width: 100%;
          height: 13vh;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .pricing_card_free_button{
          width: 100%;
          height: 5vh;
          display: flex;
          align-items: center;
          justify-content: center;
          button{
            background-color: rgba(65, 61, 250, 1);
            color: white;
            border: 0px solid red;
            height: 7vh;
            width: 100%;
            border-radius: 40px;
            font-size: 15px;
            cursor: pointer;
          }
        }
      }
      .pricing_card_free_contain{
        height: 75vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 25px;
        margin-top: 350px;
        padding-left: 25px;
        color: rgba(246, 247, 248, 1);
        .check{
          height: 2vh;
          display: flex;
          align-items: center;
          gap: 10px;
          p{
            font-size: 13px;
          }
        }
      }
    }
  }
  
}
