.home {
  height: 180vh;
  background: linear-gradient(rgba(9, 53, 150, 1), rgba(3, 17, 48, 1));
  width: 100%;
  scrollbar-width: thin;
  .home_navbar {
    height: 16vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .home_verson_button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 40px;
      border: 2px solid white;
      height: 40px;
      border-radius: 50px;
      background-color: transparent;
      padding: 10px;
      color: white;
      padding-left: 20px;
      padding-right: 20px;
      font-weight: 100;
      gap: 5px;
      cursor: pointer;
    }
  }
  .home_heading {
    width: 100%;
    height: 55vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: rgb(255, 255, 255);
    gap: 20px;
    justify-content: space-between;
    h1 {
      margin-top: 10px;
      font-size: 64px;
      text-align: center;
      font-family: "Roboto", sans-serif;
      font-weight: 500;
    }
    p {
      text-align: center;
      font-size: 18px;
      font-family: "Roboto", sans-serif;
      font-weight: 400;
      font-style: normal;
    }
    .home_heading_buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 25px;
      button {
        cursor: pointer;
      }
      .but-1 {
        padding: 18px;
        padding-left: 25px;
        width: 150px;
        border-radius: 50px;
        border: 1px solid white;
        font-family: "Roboto", sans-serif;
        font-size: 12px;
        font-weight: 500;
        color: rgb(60, 77, 255);
        background-color: white;
      }
      .but-2 {
        padding: 18px;
        width: 150px;
        border-radius: 50px;
        border: 0px solid black;
        font-family: "Roboto", sans-serif;
        font-size: 12px;
        font-weight: 500;
        background-color: rgb(60, 77, 255);
        color: white;
      }
    }
    .home_heading_flash_creditcard {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;

      .home_heading_cards {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 2px;
        h6 {
          font-family: "Roboto", sans-serif;
          font-size: 18px;
          font-weight: 500;
        }
      }
    }
  }
  .home_vedio {
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .home_vedio_vedio {
      height: 90vh;
      width: 80%;
      background-color: white;
      border-radius: 20px;
      img {
        height: 100%;
        width: 100%;
        border-radius: 20px;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .home {
    height: 155vh;
    background: linear-gradient(rgba(9, 53, 150, 1), rgba(3, 17, 48, 1));
    width: 100%;
    scrollbar-width: thin;
    .home_navbar {
      height: 16vh;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .home_verson_button {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
        border: 1px solid white;
        height: 30px;
        border-radius: 50px;
        background-color: transparent;
        padding: 10px;
        color: white;
        padding-left: 20px;
        padding-right: 20px;
        font-weight: 100;
        font-size: 10px;
        gap: 5px;
        cursor: pointer;
      }
    }
    .home_heading {
      width: 100%;
      height: 50vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: rgb(255, 255, 255);
      gap: 10px;
      justify-content: space-between;
      h1 {
        margin-top: 10px;
        font-size: 30px;
        text-align: center;
        font-family: "Roboto", sans-serif;
        font-weight: 500;
      }
      p {
        text-align: center;
        font-size: 12px;
        font-family: "Roboto", sans-serif;
        font-weight: 400;
        font-style: normal;
      }
      .home_heading_buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 25px;
        button {
          cursor: pointer;
        }
        .but-1 {
          padding: 18px;
          padding-left: 25px;
          width: 150px;
          border-radius: 50px;
          border: 1px solid white;
          font-family: "Roboto", sans-serif;
          font-size: 12px;
          font-weight: 500;
          color: rgb(60, 77, 255);
          background-color: white;
        }
        .but-2 {
          padding: 18px;
          width: 150px;
          border-radius: 50px;
          border: 0px solid black;
          font-family: "Roboto", sans-serif;
          font-size: 12px;
          font-weight: 500;
          background-color: rgb(60, 77, 255);
          color: white;
        }
      }
      .home_heading_flash_creditcard {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;

        .home_heading_cards {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 2px;
          h6 {
            font-family: "Roboto", sans-serif;
            font-size: 12px;
            font-weight: 500;
          }
        }
      }
    }
    .home_vedio {
      height: 70vh;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .home_vedio_vedio {
        height: 60vh;
        width: 80%;
        background-color: white;
        border-radius: 20px;
      }
    }
  }
}
@media (min-width: 430px) and (max-width: 768px) {
  .home {
    height: 105vh;
    background: linear-gradient(rgba(9, 53, 150, 1), rgba(3, 17, 48, 1));
    width: 100%;
    scrollbar-width: thin;
    .home_navbar {
      height: 50px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .home_verson_button {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0px;
        border: 1px solid white;
        height: 30px;
        border-radius: 50px;
        background-color: transparent;
        padding: 10px;
        color: white;
        padding-left: 10px;
        padding-right: 10px;
        font-weight: 500;
        gap: 5px;
        cursor: pointer;
        font-size: 8px;
        margin-top: 20px !important;
      }
    }
    .home_heading {
      width: 100%;
      height: 40vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: rgb(255, 255, 255);
      gap: 5px;
      justify-content: space-between;
      h1 {
        margin-top: 10px;
        font-size: 22px;
        text-align: center;
        font-family: "Roboto", sans-serif;
        font-weight: 500;
      }
      p {
        text-align: center;
        font-size: 6.5px;
        font-family: "Roboto", sans-serif;
        font-weight: 400;
        font-style: normal;
      }
      .home_heading_buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 20px;
        button {
          cursor: pointer;
        }
        .but-1 {
          padding: 10px;
          padding-left: 15px;
          width: 250px;
          border-radius: 50px;
          border: 0px solid black;
          font-family: "Roboto", sans-serif;
          font-size: 12px;
          font-weight: 500;
          color: rgb(60, 77, 255);
        }
        .but-2 {
          padding: 10px;
          width: 250px;
          border-radius: 50px;
          border: 0px solid black;
          font-family: "Roboto", sans-serif;
          font-size: 12px;
          font-weight: 500;
          background-color: rgb(60, 77, 255);
          color: white;
        }
      }
      .home_heading_flash_creditcard {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;

        .home_heading_cards {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 2px;
          h6 {
            font-family: "Roboto", sans-serif;
            font-size: 10px;
            font-weight: 500;
          }
        }
      }
    }
    .home_vedio {
      height: 45vh;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.2s ease-in-out;
      .home_vedio_vedio {
        height: 40vh;
        width: 80%;
        background-color: white;
        border-radius: 20px;
        transition: all 0.2s ease-in-out;
      }
    }
  }
}

@media all and (max-width: 430px) {
  .home {
    height: 88vh;
    background: linear-gradient(rgba(9, 53, 150, 1), rgba(3, 17, 48, 1));
    width: 100%;
    scrollbar-width: thin;
    .home_navbar {
      height: 50px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .home_verson_button {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0px;
        border: 1px solid white;
        height: 30px;
        border-radius: 50px;
        background-color: transparent;
        padding: 10px;
        color: white;
        padding-left: 10px;
        padding-right: 10px;
        font-weight: 500;
        gap: 5px;
        cursor: pointer;
        font-size: 8px;
        margin-top: 20px !important;
      }
    }
    .home_heading {
      width: 100%;
      height: 40vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: rgb(255, 255, 255);
      gap: 5px;
      justify-content: space-between;
      h1 {
        margin-top: 10px;
        font-size: 22px;
        text-align: center;
        font-family: "Roboto", sans-serif;
        font-weight: 500;
      }
      p {
        text-align: center;
        font-size: 6.5px;
        font-family: "Roboto", sans-serif;
        font-weight: 400;
        font-style: normal;
      }
      .home_heading_buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 20px;
        button {
          cursor: pointer;
        }
        .but-1 {
          padding: 10px;
          padding-left: 15px;
          width: 250px;
          border-radius: 50px;
          border: 0px solid black;
          font-family: "Roboto", sans-serif;
          font-size: 12px;
          font-weight: 500;
          color: rgb(60, 77, 255);
        }
        .but-2 {
          padding: 10px;
          width: 250px;
          border-radius: 50px;
          border: 0px solid black;
          font-family: "Roboto", sans-serif;
          font-size: 12px;
          font-weight: 500;
          background-color: rgb(60, 77, 255);
          color: white;
        }
      }
      .home_heading_flash_creditcard {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;

        .home_heading_cards {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 2px;
          h6 {
            font-family: "Roboto", sans-serif;
            font-size: 10px;
            font-weight: 500;
          }
        }
      }
    }
    .home_vedio {
      height: 32vh;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.2s ease-in-out;
      .home_vedio_vedio {
        height: 25vh;
        width: 80%;
        background-color: white;
        border-radius: 20px;
      }
    }
  }
}
