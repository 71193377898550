.prod_action{
    height: 60vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(9, 53, 150, 1);
    font-family: "Roboto", sans-serif;
    padding-bottom: 50px;
    .prod_action_card{
        height: 50vh;
        width: 80%;
        border: 1px solid white;
        color: white;
        border-radius: 40px;
        display: flex;align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 30px;
        h1{
            font-size: 40px;
            font-weight: 700;
        }
        p{
            font-weight: 400;
            font-size: 16px;
        }
        button{
            height: 56px;
            width: 200px;
            font-size: 16px;
            font-weight: 700;
            border-radius: 30px;
            border: 1px solid white;
            color: rgba(60, 77, 255, 1);
            cursor: pointer;
        }
    }
}