.modal-overlay1 {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  
  .modal-overlay1.show {
    opacity: 1;
  }
  
  .modal-content1 {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    max-width: 400px;
    width: 100%;
    transform: scale(0);
    transition: transform 0.3s ease-in-out;
  }
  
  .modal-content1 img {
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
  
  .modal-content1 p {
    margin: 0;
    font-size: 16px;
    color: #333;
  }
  
  @keyframes heartbeat {
    0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
  }
  
  .modal-content1.scale-up {
    transform: scale(1);
    animation: heartbeat 5s infinite;
  }
  
  .modal-content1.scale-down {
    transform: scale(0);
  }
