.terms {
  height: 1270vh;
  background-color: whitesmoke;
  width: 100%;
  .terms_navbar {
    height: 16vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .trems_banner {
    height: 30vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .terms_banner_banner2 {
      margin-top: 100px;
      display: flex;
      height: 15vh;
      width: 60%;
      background-color: rgb(86, 255, 187);
      // flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 70px;
      border-radius: 30px;
      .icon1 {
        font-size: 150px;
        font-weight: 300;
        color: rgb(35, 34, 34);
      }
      .privacy_terms {
        display: flex;
        flex-direction: column;
        gap: 10px;
        h3 {
          font-size: 30px;
          font-family: "Roboto", sans-serif;
          color: rgb(57, 56, 53);
        }
        p {
          font-family: "Roboto", sans-serif;
        }
      }
    }
  }

  .terms_interpretation {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    font-family: "Roboto", sans-serif;
    margin-top: 130px;
    .terms_lastupdate {
      width: 80%;
      height: 10px;
      p {
        font-weight: bold;
      }
      .span {
        color: blue;
      }
    }
    .terms_summary_key {
      width: 80%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 40px;

      h4 {
        font-size: 18px;
        span {
          color: rgb(0, 30, 255);
        }
        .p2 {
          font-weight: bold;
          color: black;
        }
      }
      p {
        font-size: 18px;
        span {
          color: rgb(0, 30, 255);
        }
        .p2 {
          font-weight: bold;
          color: black;
        }
      }
      .p1 {
        font-size: 17.6px;
        font-weight: bold;
        span {
          color: rgb(0, 30, 255);
        }
      }
    }
    .terms_ques_ans_1 {
      display: flex;
      width: 52%;
      height: auto;
      flex-direction: column;
      align-items: start;
      margin-top: 40px;
      gap: 10px;

      h4 {
        // font-size: 18px;
        span {
          color: rgb(0, 30, 255);
        }
        .p2 {
          font-weight: bold;
          color: black;
        }
      }
      h1 {
        font-size: 25px;
        font-family: "Roboto", sans-serif;
        color: rgb(57, 56, 53);
      }
      p {
        font-size: 18px;
        span {
          color: rgb(0, 30, 255);
        }
        .p2 {
          font-weight: bold;
          color: black;
        }
      }
      .p1 {
        font-size: 17.6px;
        font-weight: bold;
        span {
          color: rgb(0, 30, 255);
        }
      }
    }
    .terms_ques_ans {
      display: flex;
      width: 52%;
      height: auto;
      flex-direction: column;
      align-items: start;
      margin-top: 40px;
      gap: 30px;

      h4 {
        // font-size: 18px;
        span {
          color: rgb(0, 30, 255);
        }
        .p2 {
          font-weight: bold;
          color: black;
        }
      }
      h1 {
        font-size: 25px;
        font-family: "Roboto", sans-serif;
        color: rgb(57, 56, 53);
      }
      p {
        font-size: 18px;
        span {
          color: rgb(0, 30, 255);
        }
        .p2 {
          font-weight: bold;
          color: black;
        }
      }
      .p1 {
        font-size: 17.6px;
        font-weight: bold;
        span {
          color: rgb(0, 30, 255);
        }
      }
    }
  }
}
.terms_footer {
  height: 45vh;
  width: 100%;
  // margin-top: 6500px !important;
}

@media (max-width: 376px) {
  .terms {
    height: 860vh !important;
    background-color: rgb(255, 255, 255);
    width: 100%;
    .terms_navbar {
      height: 50px !important;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .trems_banner {
      height: 10vh !important;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .terms_banner_banner2 {
        margin-top: 100px;
        display: flex;
        height: 10vh;
        width: 60%;
        background-color: rgb(86, 255, 187);
        // flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 30px;
        border-radius: 30px;
        .icon1 {
          font-size: 60px;
          font-weight: 300;
          color: rgb(35, 34, 34);
        }
        .privacy_terms {
          h3 {
            font-size: 15px;
            font-family: "Roboto", sans-serif;
            color: rgb(57, 56, 53);
          }
          p {
            font-size: 9px !important;
          }
        }
        // h3{
        //     font-size: 15px;
        //     font-family: "Roboto", sans-serif;
        //     color: rgb(57,56,53);
        // }
      }
    }
    .terms_interpretation {
      width: 100%;
      height: 480vh !important;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: start;
      // border: 2px solid black !important;
      // margin-top: 2600px !important;

      .terms_ques_ans_1 {
        display: flex;
        width: 70% !important;
        height: auto;
        flex-direction: column;
        align-items: start;
        margin-top: 0px !important;
        gap: 10px;

        h4 {
          font-size: 10px !important;
          span {
            color: rgb(0, 30, 255);
          }
          .p2 {
            font-weight: bold;
            color: black;
          }
        }
        h1 {
          font-size: 12px !important;
          font-family: "Roboto", sans-serif;
          color: rgb(57, 56, 53);
        }
        p {
          font-size: 8px !important;
          span {
            color: rgb(0, 30, 255);
          }
          .p2 {
            font-weight: bold;
            color: black;
          }
        }
        .p1 {
          font-size: 7.6px !important;
          font-weight: bold;
          span {
            color: rgb(0, 30, 255);
          }
        }
      }
      .terms_ques_ans {
        display: flex;
        width: 70% !important;
        height: auto;
        flex-direction: column;
        align-items: start;
        margin-top: 40px;
        gap: 30px;

        h3 ,h4 {
          font-size: 10px !important;
          span {
            color: rgb(0, 30, 255);
          }
          .p2 {
            font-size: 10px !important;
            font-weight: bold;
            color: black;
          }
        }
        h1 {
          font-size: 14px !important;
          font-family: "Roboto", sans-serif;
          color: rgb(57, 56, 53);
        }
        li{
          font-size: 8px;
        }
        p {
          font-size: 8px !important;
          span {
            color: rgb(0, 30, 255);
          }
          .p2 {
            font-weight: bold;
            color: black;
          }
        }
        .p1 {
          font-size: 10.6px;
          font-weight: bold;
          span {
            color: rgb(0, 30, 255);
          }
        }
      }
    }
  }
  .terms_footer {
    height: 55vh !important;
    width: 100%;
    margin-top: 2400px !important;
  }
}

@media (min-width: 377px) and (max-width: 765px) {
  .terms {
    height: 400vh !important;
    background-color: rgb(255, 255, 255);
    width: 100%;
    .terms_navbar {
      height: 50px !important;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .trems_banner {
      height: 10vh !important;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .terms_banner_banner2 {
        margin-top: 100px;
        display: flex;
        height: 10vh;
        width: 60%;
        background-color: rgb(86, 255, 187);
        // flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 30px;
        border-radius: 30px;
        .icon1 {
          font-size: 60px;
          font-weight: 300;
          color: rgb(35, 34, 34);
        }
        .privacy_terms {
          h3 {
            font-size: 15px;
            font-family: "Roboto", sans-serif;
            color: rgb(57, 56, 53);
          }
          p {
            font-size: 9px !important;
          }
        }
        // h3{
        //     font-size: 15px;
        //     font-family: "Roboto", sans-serif;
        //     color: rgb(57,56,53);
        // }
      }
    }
    .terms_interpretation {
      width: 100%;
      height: 310vh !important;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: start;
      // border: 2px solid black !important;
      // margin-top: 2600px !important;

      .terms_ques_ans_1 {
        display: flex;
        width: 70% !important;
        height: auto;
        flex-direction: column;
        align-items: start;
        margin-top: 0px !important;
        gap: 10px;

        h4 {
          font-size: 10px !important;
          span {
            color: rgb(0, 30, 255);
          }
          .p2 {
            font-weight: bold;
            color: black;
          }
        }
        h1 {
          font-size: 12px !important;
          font-family: "Roboto", sans-serif;
          color: rgb(57, 56, 53);
        }
        p {
          font-size: 8px !important;
          span {
            color: rgb(0, 30, 255);
          }
          .p2 {
            font-weight: bold;
            color: black;
          }
        }
        .p1 {
          font-size: 7.6px !important;
          font-weight: bold;
          span {
            color: rgb(0, 30, 255);
          }
        }
      }
      .terms_ques_ans {
        display: flex;
        width: 70% !important;
        height: auto;
        flex-direction: column;
        align-items: start;
        margin-top: 40px;
        gap: 30px;

        h3 ,h4 {
          font-size: 10px !important;
          span {
            color: rgb(0, 30, 255);
          }
          .p2 {
            font-size: 10px !important;
            font-weight: bold;
            color: black;
          }
        }
        h1 {
          font-size: 14px !important;
          font-family: "Roboto", sans-serif;
          color: rgb(57, 56, 53);
        }
        li{
          font-size: 8px;
        }
        p {
          font-size: 8px !important;
          span {
            color: rgb(0, 30, 255);
          }
          .p2 {
            font-weight: bold;
            color: black;
          }
        }
        .p1 {
          font-size: 10.6px;
          font-weight: bold;
          span {
            color: rgb(0, 30, 255);
          }
        }
      }
    }
  }
  .terms_footer {
    height: 55vh !important;
    width: 100%;
    margin-top: 2600px !important;
  }
}
