.nav-link{
    text-decoration: none;
    color:  black;
}
.nav-link_1{
    text-decoration: none;
    color:  rgb(255, 255, 255);
}
.nav-link_2{
    color:rgb(84,99,254);
}
.custom-cursor {
    width:50px;
    height: 50px;
    border-radius: 50%;
    position: absolute;
    pointer-events:none;
    background-color: rgb(205, 195, 195);
    z-index: 10000;
}