.productivity {
  height: 70vh;
  background: linear-gradient(rgba(3, 17, 48, 1), rgba(9, 53, 150, 1));
  width: 100%;
  font-family: "Roboto", sans-serif;
  .productivity_nav {
    height: 20vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    color: white;
    padding-top: 40px;
    h1 {
      font-size: 36px;
    }
    p {
      font-weight: 300;
      font-size: 18px;
      margin-top: 10px;
      margin-bottom: 20px;
    }
  }
  .productivity_crm_card {
    height: 30vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    .center_it {
      width: 80%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      gap: 30px;
    }
    .crm_cards {
      height: 12vh;
      width: 65vh;
      background-color: rgb(252, 248, 248);
      display: flex;
      align-items: center;
      margin-left: 15vh;
      // justify-content: center;
      border-radius: 20px;
      gap: 15px;
      // margin-right: 10px;
      box-shadow: rgba(252, 252, 252, 0.964) 0px 1px 2px 0px,
        rgba(238, 240, 242, 0.15) 0px 2px 6px 2px;
      .crm_card_icon {
        height: 6vh;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 6vh;
        color: white;
        border-radius: 10px;
        background: linear-gradient(rgba(60, 77, 255, 1), rgba(36, 46, 153, 1));
        margin-left: 20px;
      }
      h4 {
        font-size: 18px;
      }
      p {
        font-size: 15px;
      }
    }
    .crm_cards_1 {
      height: 12vh;
      width: 85vh;
      background-color: rgb(252, 248, 248);
      display: flex;
      align-items: center;
      margin-left: 0vh;
      // justify-content: center;
      border-radius: 30px;
      gap: 40px;
      box-shadow: rgba(252, 252, 252, 0.964) 0px 1px 2px 0px,
        rgba(238, 240, 242, 0.15) 0px 2px 6px 2px;
      .crm_card_icon {
        height: 8vh;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 8vh;
        color: white;
        border-radius: 20px;
        background: linear-gradient(rgba(60, 77, 255, 1), rgba(36, 46, 153, 1));
        margin-left: 40px;
      }
      h4 {
        font-size: 15px;
      }
      p {
        font-size: 13px;
      }
    }
  }
  .productivity_features_heading {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    color: white;
    margin-top: 10vh;
    p {
      font-size: 12px;
      font-weight: 400;
    }
  }
  .productivity_featires {
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .productivity_featires_card {
      height: 85vh;
      width: 80%;
      background-color: white;
      border-radius: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .productivity_featires_card_navbar {
        height: 15vh;
        width: 100%;
        background-color: rgb(255, 255, 255);
        display: flex;
        border-radius: 30px;
        align-items: center;
        justify-content: center;
        .productivity_featires_card_navbar_buttons {
          height: 10vh;
          width: 90%;
          background: linear-gradient(
            to right,
            rgba(9, 53, 150, 1),
            rgba(3, 17, 48, 1)
          );
          border-radius: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          button {
            height: 8vh;
            width: 24.5%;
            border-radius: 40px;
            border: 0px solid black;
            font-size: 14px;
            font-weight: 600;
            color: white;
            cursor: pointer;
            background-color: transparent;
            transition: 500ms;
            &:hover {
              background-color: white;
              color: black;
            }
          }
        }
      }
      .productivity_features_main {
        height: 70vh;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .productivity_features_main_card {
          height: 60vh;
          width: 90%;
          border: 2px solid rgb(44, 57, 188);
          border-radius: 35px;
          .productivity_features_main_card_maim {
            height: 50vh;
          }
          .productivity_features_main_card_bottom {
            height: 10vh;
            width: 100%;
            border: 1px solid rgb(44, 57, 188);
            border-radius: 40px;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            transition: 500ms;
            p {
              width: 66%;
              font-size: 17px;
              font-weight: 500;
              color: rgb(29, 28, 28);
              // margin-left: 5vh;
            }

            button {
              // margin-right: 7vh;
              background: transparent;
              height: 7vh;
              width: 18%;
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 10px;
              font-size: 12px;
              font-weight: 600;
              cursor: pointer;

              border: 2px solid black;
              // border-image: linear-gradient(to bottom right, #b827fc, #2c90fc, #b8fd33, #fec837, #fd1892)1;
              border-radius: 30px; /* Apply border radius to the border */
              .icons_5 {
                font-size: 15px;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .productivity {
    height: 180vh;
    background: linear-gradient(rgba(9, 53, 150, 1), rgba(3, 17, 48, 1));
    width: 100%;
    font-family: "Roboto", sans-serif;
    .productivity_nav {
      height: 20vh;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 20px;
      color: white;

      p {
        font-weight: 300;
      }
    }
    .productivity_crm_card {
      height: 40vh;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 40px;
      .center_it {
        width: 80% !important;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 60px;
      }

      .crm_cards {
        height: 9vh;
        width: 35vh;
        background-color: rgb(252, 248, 248);
        display: flex;
        align-items: center;
        place-self: center;
        margin-left: 0vh;
        // justify-content: center;
        border-radius: 20px;
        gap: 15px;
        // margin-right: 10px;
        box-shadow: rgba(252, 252, 252, 0.964) 0px 1px 2px 0px,
          rgba(238, 240, 242, 0.15) 0px 2px 6px 2px;
        .crm_card_icon {
          height: 6vh;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 6vh;
          color: white;
          border-radius: 10px;
          background: linear-gradient(
            rgba(60, 77, 255, 1),
            rgba(36, 46, 153, 1)
          );
          margin-left: 20px;
        }
        h4 {
          font-size: 16px;
        }
        p {
          font-size: 13px;
        }
      }
      .crm_cards_1 {
        height: 8vh;
        width: 45vh;
        background-color: rgb(252, 248, 248);
        display: flex;
        align-items: center;
        margin-left: 0vh;
        // justify-content: center;
        border-radius: 20px;
        gap: 15px;
        box-shadow: rgba(252, 252, 252, 0.964) 0px 1px 2px 0px,
          rgba(238, 240, 242, 0.15) 0px 2px 6px 2px;
        .crm_card_icon {
          height: 6vh;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 6vh;
          color: white;
          border-radius: 10px;
          background: linear-gradient(
            rgba(60, 77, 255, 1),
            rgba(36, 46, 153, 1)
          );
          margin-left: 20px;
        }
        h4 {
          font-size: 14px;
        }
        p {
          font-size: 10px;
        }
      }
    }
    .productivity_features_heading {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 20px;
      color: white;
      margin-top: 10vh;
      p {
        font-size: 12px;
        font-weight: 400;
      }
    }
    .productivity_featires {
      height: 100vh;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .productivity_featires_card {
        height: 85vh;
        width: 80%;
        background-color: white;
        border-radius: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .productivity_featires_card_navbar {
          height: 15vh;
          width: 100%;
          background-color: rgb(255, 255, 255);
          display: flex;
          border-radius: 30px;
          align-items: center;
          justify-content: center;
          .productivity_featires_card_navbar_buttons {
            height: 10vh;
            width: 90%;
            background: linear-gradient(
              to right,
              rgba(9, 53, 150, 1),
              rgba(3, 17, 48, 1)
            );
            border-radius: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            button {
              height: 8vh;
              width: 24.5%;
              border-radius: 40px;
              border: 0px solid black;
              font-size: 14px;
              font-weight: 600;
              color: white;
              cursor: pointer;
              background-color: transparent;
              transition: 500ms;
              &:hover {
                background-color: white;
                color: black;
              }
            }
          }
        }
        .productivity_features_main {
          height: 70vh;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          .productivity_features_main_card {
            height: 60vh;
            width: 90%;
            border: 2px solid rgb(44, 57, 188);
            border-radius: 35px;
            .productivity_features_main_card_maim {
              height: 50vh;
            }
            .productivity_features_main_card_bottom {
              height: 10vh;
              width: 100%;
              border: 1px solid rgb(44, 57, 188);
              border-radius: 35px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              transition: 500ms;
              p {
                width: 66%;
                font-size: 14px;
                font-weight: 500;
                color: rgb(29, 28, 28);
                padding-left: 10px;
                // margin-left: 5vh;
              }
              button {
                // margin-right: 7vh;
                background: transparent;
                height: 6vh;
                width: 20%;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 10px;
                font-size: 10px;
                font-weight: 600;
                cursor: pointer;
                // padding-left: 10px;
                margin-right: 10px;

                border: 2px solid black;
                // border-image: linear-gradient(to bottom right, #b827fc, #2c90fc, #b8fd33, #fec837, #fd1892)1;
                border-radius: 25px; /* Apply border radius to the border */
                .icons_5 {
                  font-size: 15px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 430px) and (max-width: 768px) {
  .productivity {
    height: 110vh;
    background: linear-gradient(rgba(9, 53, 150, 1), rgba(3, 17, 48, 1));
    width: 100%;
    font-family: "Roboto", sans-serif;
    .productivity_nav {
      height: 15vh;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 20px;
      color: white;

      h1 {
        font-size: 17px;
      }
      p {
        font-weight: 300;
        font-size: 10px;
      }
    }
    .productivity_crm_card {
      height: 25vh;
      width: 100%;
      display: flex;
      // grid-template-columns: repeat(2,1fr);
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 20px;
      .center_it {
        width: 80% !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
      }
      .crm_cards_1 {
        height: 5.5vh;
        width: 80%;
        background-color: rgb(252, 248, 248);
        display: flex;
        align-items: center;
        // justify-content: center;
        border-radius: 10px;
        gap: 15px;
        margin-left: 0vh;
        // box-shadow: rgba(252, 252, 252, 0.964) 0px 1px 2px 0px, rgba(238, 240, 242, 0.15) 0px 2px 6px 2px;
        .crm_card_icon {
          height: 3.5vh;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 3.5vh;
          color: white;
          border-radius: 5px;
          background: linear-gradient(
            rgba(60, 77, 255, 1),
            rgba(36, 46, 153, 1)
          );
          margin-left: 20px;
          .icon_2 {
            font-size: 14px;
          }
        }
        h4 {
          font-size: 9px;
          width: 15%;
        }
        p {
          font-size: 8px;
          // font-weight: 600;
        }
      }
    }
    .productivity_features_heading {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 20px;
      color: white;
      margin-top: 10vh;
      p {
        font-size: 8px;
        font-weight: 400;
      }
      h1 {
        font-size: 16px;
      }
    }
    .productivity_featires {
      height: 50vh;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0px;
      .productivity_featires_card {
        height: 45vh;
        width: 80%;
        background-color: white;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2px;

        .productivity_featires_card_navbar {
          height: 15vh;
          width: 100%;
          background-color: rgb(255, 255, 255);
          display: flex;
          // border-radius: 15px;
          align-items: center;
          justify-content: center;
          .productivity_featires_card_navbar_buttons {
            height: 10vh;
            width: 70%;
            background: linear-gradient(
              to right,
              rgb(58, 74, 246),
              rgb(39, 50, 166)
            );
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 10px !important;
            button {
              height: 4vh;
              width: 80%;
              border-radius: 40px;
              border: 0px solid black;
              font-size: 8px;
              // font-weight: 600;
              color: white;
              cursor: pointer;
              background-color: transparent;
              transition: 500ms;
              &:hover {
                background-color: white;
                color: black;
              }
            }
          }
        }
        .productivity_features_main {
          height: 23vh;
          width: 100%;
          display: flex !important;
          // border: 1px solid blue;
          align-items: center;
          justify-content: center;
          // flex-direction: column;
          .productivity_features_main_card {
            height: 22vh;
            width: 75%;
            border: 1px solid rgb(44, 57, 188);
            border-radius: 10px;
            .productivity_features_main_card_maim {
              height: 22vh;
            }
            .productivity_features_main_card_bottom {
              height: 4vh;
              width: 100%;
              // border: 1px solid rgb(44,57,188);
              border-radius: 0px;
              display: flex;
              align-items: center;
              justify-content: space-evenly;
              transition: 500ms;
              // margin-left: -4vh;
              border: 0px solid black;
              p {
                // width: 70%;
                font-size: 6.5px;
                font-weight: 500;
                color: rgb(29, 28, 28);
                // margin-left: 5vh;
              }
              button {
                // margin-right: 5vh;
                background: transparent;
                height: 2.5vh;
                width: 75px;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 1px;
                font-size: 5px;
                font-weight: 600;
                cursor: pointer;

                border: 1px solid black;
                // border-image: linear-gradient(to bottom right, #b827fc, #2c90fc, #b8fd33, #fec837, #fd1892)1;
                border-radius: 30px; /* Apply border radius to the border */
                .icons_5 {
                  font-size: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 430px) {
  .productivity {
    height: 60vh !important;
    background: linear-gradient(rgba(3, 17, 48, 1),rgba(9, 53, 150, 1));
    width: 100%;
    font-family: "Roboto", sans-serif;
    .productivity_nav {
      height: 15vh;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 20px;
      color: white;

      h1 {
        font-size: 17px;
      }
      p {
        font-weight: 300;
        font-size: 10px;
      }
    }
    .productivity_crm_card {
      height: 25vh;
      width: 100%;
      display: flex;
      // grid-template-columns: repeat(2,1fr);
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 20px;
      margin-top: 20px;
      .center_it {
        width: 80% !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
      }
      .crm_cards_1 {
        height: 5.5vh;
        width: 80%;
        background-color: rgb(252, 248, 248);
        display: flex;
        align-items: center;
        // justify-content: center;
        border-radius: 10px;
        gap: 15px;
        margin-left: 0vh;
        // box-shadow: rgba(252, 252, 252, 0.964) 0px 1px 2px 0px, rgba(238, 240, 242, 0.15) 0px 2px 6px 2px;
        .crm_card_icon {
          height: 3.5vh;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 3.5vh;
          color: white;
          border-radius: 5px;
          background: linear-gradient(
            rgba(60, 77, 255, 1),
            rgba(36, 46, 153, 1)
          );
          margin-left: 20px;
          .icon_2 {
            font-size: 14px;
          }
        }
        h4 {
          font-size: 9px;
          width: 15%;
        }
        p {
          font-size: 8px;
          // font-weight: 600;
        }
      }
    }
    .productivity_features_heading {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 20px;
      color: white;
      margin-top: 10vh;
      p {
        font-size: 8px;
        font-weight: 400;
      }
      h1 {
        font-size: 16px;
      }
    }
    .productivity_featires {
      height: 50vh;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0px;
      .productivity_featires_card {
        height: 40vh;
        width: 80%;
        background-color: white;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .productivity_featires_card_navbar {
          height: 15vh;
          width: 100%;
          background-color: rgb(255, 255, 255);
          display: flex;
          // border-radius: 15px;
          align-items: center;
          justify-content: center;
          .productivity_featires_card_navbar_buttons {
            height: 10vh;
            width: 70%;
            background: linear-gradient(
              to right,
              rgb(58, 74, 246),
              rgb(39, 50, 166)
            );
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 10px !important;
            button {
              height: 4vh;
              width: 80%;
              border-radius: 40px;
              border: 0px solid black;
              font-size: 8px;
              // font-weight: 600;
              color: white;
              cursor: pointer;
              background-color: transparent;
              transition: 500ms;
              &:hover {
                background-color: white;
                color: black;
              }
            }
          }
        }
        .productivity_features_main {
          height: 18vh;
          width: 100%;
          display: flex !important;
          // border: 1px solid blue;
          align-items: center;
          justify-content: center;
          // flex-direction: column;
          .productivity_features_main_card {
            height: 15vh;
            width: 75%;
            border: 1px solid rgb(44, 57, 188);
            border-radius: 10px;
            .productivity_features_main_card_maim {
              height: 16.5vh;
            }
            .productivity_features_main_card_bottom {
              height: 4vh;
              width: 100%;
              // border: 1px solid rgb(44,57,188);
              border-radius: 0px;
              display: flex;
              align-items: center;
              justify-content: space-evenly;
              transition: 500ms;
              // margin-left: -4vh;
              border: 0px solid black;
              p {
                // width: 70%;
                font-size: 6.5px;
                font-weight: 500;
                color: rgb(29, 28, 28);
                // margin-left: 5vh;
              }
              button {
                // margin-right: 5vh;
                background: transparent;
                height: 2.5vh;
                width: 75px;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 1px;
                font-size: 5px;
                font-weight: 600;
                cursor: pointer;

                border: 1px solid black;
                // border-image: linear-gradient(to bottom right, #b827fc, #2c90fc, #b8fd33, #fec837, #fd1892)1;
                border-radius: 30px; /* Apply border radius to the border */
                .icons_5 {
                  font-size: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
}
