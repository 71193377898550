.scrachcard_main{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        height: 100vh;
        font-family: "Roboto", sans-serif;
        background: linear-gradient(rgba(9, 53, 150, 1), rgba(3, 17, 48, 1));
    .scraechcard_card{
        height: 90vh;
        width: 25%;
        background-color: white;
        background-size: cover;
        // border-radius: 30px;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        gap: 5px;
        .scrachcard_card_nav{
            height: 8vh;
            width: 80%;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
            border: 2px solid rgba(162, 162, 162, 1);
            border-radius: 30px;
            margin-top: 40px;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

            h5{
                font-weight: 600;
                font-size: 18px;
                color: rgba(59, 59, 59, 1);

            }
            
        }
        .scrachcard_card_heading{
            height: 15vh;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 10px;
            h3{
                font-size: 30px;
                // color: rgba(162, 162, 162, 1);
            }
            p{
                font-size: 13px;
                font-weight: 400;
                color: rgb(53, 51, 51);
            }
        }
        .scrachcard_card_heading_2{
            height: 15vh;
            width: 70%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            h3{
                font-size: 18px;
                color: rgb(45, 43, 43);
            }
        }
        .scrachcard_card_heading_3{
            height: 15vh;
            width: 90%;
            display: flex;
            align-items: center;
            justify-content: center;
            p{
                font-size: 20px;
                font-weight: 500;
                background-color: rgba(41, 42, 45, 1);
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                font-family: 'Poppins';

                
                padding: 5px;
                padding-left: 20px;
                padding-right: 20px;
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                gap:10px;
                color: white;
                img{
                    height: 30px;
                }
                cursor: pointer;
                
            }
        }
        
    }
    .image {
      height: 200px;
      width: 260px;
    }
}


@media all and (max-width: 430px) {

    .scrachcard_main{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        height: 100vh;
        font-family: "Roboto", sans-serif;
        background: linear-gradient(rgba(9, 53, 150, 1), rgba(3, 17, 48, 1));
        .scrachcard_navbar{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1000;
        }
        .scraechcard_card{
            height: 78vh;
            width: 85%;
            background-color: white !important;
            border-radius: 0px !important;
            display: flex;
            flex-direction: column;
            justify-content: start;
            align-items: center;
            gap: 5px; 
            }
            .scrachcard_card_heading{
                height: 10vh !important;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                h3{
                    font-size: 25px !important;
                }
                p{
                    font-size: 10px !important;
                }
            }
            .scrachcard_card_heading_2{
                height: 10vh !important;
                width: 70%;
                display: flex;
                align-items: center;
                justify-content: center;
                h3{
                    font-size: 15px !important;
                    font-weight: 500;
                    span{
                        color: blue;
                    }
                }
            }
            .scrachcard_card_heading_3{
                height: 10vh !important;
                width: 90%;
                display: flex;
                align-items: center;
                justify-content: center;
                p{
                    font-size: 10px !important;
                    font-weight: 500;
                    background-color: rgba(41, 42, 45, 1);
                    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                    font-family: 'Poppins';
    
                    
                    padding: 5px;
                    padding-left: 20px;
                    padding-right: 20px;
                    border-radius: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap:10px;
                    color: white;
                    img{
                        height: 20px !important;
                    }
                    cursor: pointer;
                    
                }
            }
            .scrachcard_card_nav{
                height: 8vh;
                width: 70% !important;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 5px;
                border: 2px solid rgba(162, 162, 162, 1);
                border-radius: 30px;
                margin-top: 20px !important;
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

                h5{
                    font-weight: 600;
                    font-size: 13px !important;
                    color: rgba(59, 59, 59, 1);

                }
            }
            
        }
        .image{
            height: 200px;
            width:260px;
        }
}
